html,
body,
article,
section,
header,
footer,
nav,
div,
dl,
dt,
dd,
hr,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
th,
td {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  text-indent: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif, Microsoft Yahei;
  color: #333;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  font-style: normal;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

input,
select,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
}

input,
select,
textarea,
button,
datalist,
option {
  outline: 0;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}
