.login-wrap {
  position: fixed;
  display: flex;
  top: 0;
  right:0;
  bottom: 0;
  left: 0;
  z-index: 101;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.login-hold {
  display: flex;
  flex-basis: 420px;
  height: 100%;
  flex-direction: column;
  justify-content: center;    
  padding-bottom: 172px;
}
.logo-hold {
  display: flex;
  flex-basis: 60px;
  justify-content: center;
  align-items: center;
}

.logo-hold .logo-ank {
  display: block;
  width: 60px;
  height: 60px;
}
.logo-hold .logo-ank img {
  display: block;
  width: 100%;
  height: 100%;
}
.title-hold {
  line-height: 44px;
  text-align: center;
  margin-bottom: 36px;
  font-size: 18rem;
  font-weight: bold;
}
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 278px;
}

.login-form .form-g {
  display: flex;
  flex-direction: column;
  flex-basis: 76px;
  margin-top: 18px;
  font-size: 12rem;
}

.login-form .form-g .inp-hold {
  position: relative; 
  height: 40px;
  box-sizing: border-box;
  padding: 0 26px 0 12px;
  border-radius: 3px;
  border: 1px solid #d3d3d3;
}

.login-form .form-g .inp-hold.focus,
.login-form .form-g .inp-hold-vice.focus {
  border: 1px solid #121822;
}

.login-form .form-g .inp-hold.err,
.login-form .form-g .inp-hold-vice.err {
  border: 1px solid #f04d4d;
}

.login-form .form-g .inp-hold-vice {
  position: relative; 
  height: 40px;
  box-sizing: border-box;
  padding: 0 84px 0 12px;
  border-radius: 3px;
  border: 1px solid #d3d3d3;
}

.login-form .form-g .inp-hold-vice.wait {
  padding-right: 146px;
}

.login-form .form-g .inp-hold-vice.wait .vice-btn {
  width: 146px;
}

.login-form .form-g .inp-hold .clear-hold {
  position: absolute;
  display: block;
  width: 26px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 12rem;
  color: #fff;
}

.login-form .form-g .inp-hold .clear-hold:before {
  position: absolute;
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 7px;
  left: 6px;
  top: 12px;
  color: #fff;
  background-color: #999;
}

.login-form .form-g .inp-hold .clear-hold .clear-cross {
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  left: 6px;
  top: 12px;
  line-height: 14px;
  transform: scale(0.7);
}


.login-form .form-g .inp-hold .real-inp,
.login-form .form-g .inp-hold-vice .real-inp {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  line-height: 38px;
}

.login-form .form-g .inp-hold-vice .vice-btn {
  position: absolute;
  display: block;
  width: 84px;
  height: 38px;
  top: 0;
  right: 0;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  font-size: 12rem;
  color: #999;
  border-left: 1px solid #d3d3d3;
}

.login-form .form-g .inp-hold-vice .vice-btn.active {
  color: inherit;
}

.login-form .form-g .err-para {
  line-height: 36px;
  box-sizing: border-box;
  padding-left: 12px;
  color: #f04d4d;
}

.submit-btn {
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  font-size: 14rem;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  color: #fff;
  background-color: #ccc;
}
.submit-btn.active {
  background-color: #121822;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-spinner div {
  transform-origin: 20px 20px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  left: 19px;
  width: 2px;
  height: 4px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input::-moz-outer-spin-button,
input::-moz-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-ms-clear {
  display: none;
}

.modal-shade {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 158px;
  box-sizing: border-box;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: HSLA(0, 0%, 0%, 0.6)
}

.modal-body {
  display: flex;
  width: 434px;
  height: 213px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  text-align: center;
  background-color: #fff;
}

.modal-body .prompt-tit {
  width: 184px;
  line-height: 36px;
  font-size: 16rem;
  font-weight: bold;
}

.modal-body .prompt-msg {
  text-align: center;
  font-size: 14px;
  color: rgba(18, 24, 34, 0.85);
  line-height: 24px;
  margin-bottom: 30px;
}

.modal-body .re-operate {
  width: 160px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  font-size: 12rem;
  cursor: pointer;
  color: #fff;
  background-color: #121822;
}
.modal-body .icon-type-container{
  margin: 26px 0 14px;
  width: 100%;
  text-align: center;
}